import React from "react"
import { Link } from "gatsby"

type GatsbyTransitionLinkProps = {
  children: React.ReactNode
  state?: object
  to: string
  className?: string
  activeClassName?: string
  onClick?: Function
}

const GatsbyTransitionLink = ({ children, state, to, className, activeClassName, onClick }: GatsbyTransitionLinkProps) => {
  return (
    <Link className={className} onClick={onClick} to={to} activeClassName={activeClassName}>
      {children}
    </Link>
  )
}

export default GatsbyTransitionLink
