import { useEffect, useState } from "react";

export const useScrollDirection = () => {
  const [scrollDirection, setScrollDirection] = useState(null)
  const [prevOffset, setPrevOffset] = useState(0)
  const toggleScrollDirection = () => {
    let scrollY = window.scrollY
    if (scrollY < 300) {
        setScrollDirection(null)
    }
    if (scrollY > prevOffset && (scrollY - prevOffset > 100) && scrollY > 300) {
        setScrollDirection("down")
    } else if (scrollY < prevOffset && (prevOffset - scrollY > 100) && scrollY > 300) {
        setScrollDirection("up")
    }
    setPrevOffset(scrollY)
  }
  useEffect(() => {
    window.addEventListener("scroll", toggleScrollDirection)
    return () => {
      window.removeEventListener("scroll", toggleScrollDirection)
    }
  })
  return scrollDirection
};