import * as React from "react"
import { motion } from "framer-motion"
import GatsbyTransitionLink from "@components/elements/GatsbyTransitionLink"
import { useStateContext } from "@context/stateContext"
import gsap from "gsap"
import getTransition from "./../../utils/getTransition"
import { useScrollDirection } from "@hooks/useScrollDirection"
import logoSrcWhite from "@images/logo-white.svg"
import logoSrcBlack from "@images/logo.svg"
import logoSrcWhitePlus from "@images/logo-plus-white.svg"
import logoSrcBlackPlus from "@images/logo-plus-black.svg"

type LogoProps = {
  headColor: string
  location: object
  darkModeActive?: boolean
}

export default function Logo({ headColor, location, darkModeActive }: LogoProps) {
  const scrollDirection = useScrollDirection()
  const logoRef = React.useRef()
  const { navOpen, setMouseInteracting, setTsA, setLobsterGame, logoBlack, setLogoBlack, language, darkMode } = useStateContext()

  const navItemClick = (e, to, transitionState) => {
    e.preventDefault()
    setLobsterGame(false)
    setTsA(transitionState)
    getTransition(to, transitionState)
  }

  React.useEffect(() => {
    if (scrollDirection === null) {
      gsap.to(logoRef.current, { y: 0, duration: 0.5, ease: "power2" })
    }
    if (scrollDirection === "down") {
      gsap.to(logoRef.current, { y: -200, duration: 0.5, ease: "power2" })
    }
    if (scrollDirection === "up") {
      gsap.to(logoRef.current, { y: 0, duration: 0.5, ease: "power2" })
    }
  }, [scrollDirection])

  React.useEffect(() => {
    setLogoBlack(headColor === "black")
  }, [])

  const logoDestinationTo = language === "de" ? "/" : "/en/"

  return (
    <motion.header
      ref={logoRef}
      className={`${navOpen ? "z-[1000]" : "z-[100]"} fixed left-0 right-0 flex items-center justify-center h-0 mx-auto  isolate logo-ani ${
        darkModeActive ? "top-spacing-60 xxs:top-spacing-master md:top-spacing-60 2xl:top-spacing-75" : "top-[20px] md:top-[25px] 2xl:top-[43px]"
      }`}
      onMouseEnter={() => setMouseInteracting(true)}
      onMouseLeave={() => setMouseInteracting(false)}
    >
      <GatsbyTransitionLink className="absolute top-0 flex items-center justify-center" to={logoDestinationTo} onClick={e => navItemClick(e, logoDestinationTo, "logo-home")}>
        <span className={`flex justify-content w-5/12 xxs:w-1/2 2xl:w-10/12 4xl:w-full`}>
          {!darkMode && (headColor === "black" || logoBlack) && <img src={logoSrcBlack} alt="" />}
          {(headColor !== "black" || darkMode) && <img src={logoSrcWhite} alt="" />}
        </span>
        {/* <span className={`flex md:hidden justify-content w-[50px] xxs:w-[60px]`}>
          {!darkMode && (headColor === "black" || logoBlack) && <img src={logoSrcBlackPlus} alt="" />}
          {(headColor !== "black" || darkMode) && <img src={logoSrcWhitePlus} alt="" />}
        </span> */}
      </GatsbyTransitionLink>
    </motion.header>
  )
}
