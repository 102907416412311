import React from 'react';
import tw, {styled} from 'twin.macro'
import Resources from './Resources.js';
import DinoScript from './DinoScript.js';
import DinoStyle from './DinoStyle.js';

import './Dino.css';
const HighScore = styled.div`
  ${tw`flex flex-col w-3/4 gap-5 mx-auto my-5 text-white`}

  .alltimehs{
    ${tw`pr-3`}
    ::-webkit-scrollbar {
      width: 3px;
    }
    ::-webkit-scrollbar-track {
      background: #101010 ;
      width: 3px;
    }
    ::-webkit-scrollbar-thumb {
      background: #f1fc00;
      border-radius: 0px;
      width: 3px; 
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #101010;
    }
  }
`

const ChromeDinoComponent = () => {
  const [userInput, setUserInput] = React.useState(false)
  const [gamePlaying, setGamePlaying] = React.useState(false)
  const [highscore, setHighscore] = React.useState([])
  const startDivRef = React.useRef()
  const endDivRef = React.useRef()
  const highscoreRef = React.useRef() 
  const appendDinoScript = () => {
    let dinoScriptContainer = document.createElement("script");
    dinoScriptContainer.appendChild(document.createTextNode(DinoScript)); 
    startDivRef.current.appendChild(dinoScriptContainer);
  }

  const appendRunnerScript = () => {
    let runnerScriptContainer = document.createElement("script");
    runnerScriptContainer.appendChild(document.createTextNode(`new Runner('.interstitial-wrapper');`)); 

    endDivRef.current.appendChild(runnerScriptContainer);
  }

  const getHighScore = () => {
    fetch('https://davidundmartindev.de/dino_highscore.php', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(response => response.text().then(data => JSON.parse(data)))
    .then(body => {
      if(body.length === 0) window.localStorage.clear()
      setHighscore(body)
    })
    .catch(err => {
      console.error(err)
    })
  }

  const submitHighScore = () => {
    const data = {
      userName: document.querySelector('#nickname').value,
      dinoScore: window.localStorage.dinoHighScore
    }
    setUserInput(false)


    fetch('https://davidundmartindev.de/dino_game.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    }).then(response => response.text().then(data => data))
    .then(body => {
      
      getHighScore()
    })
    .catch(err => {
      console.error(err)
    })
  }

  React.useEffect(() => {
    appendDinoScript();

    appendRunnerScript();

    getHighScore()

    window.dinoHighScore = {
      value: false,
      set: function (value) {
          this.value = value;
          this.getOnChange();
      }
    }
    window.dinoPlaying = {
      value: false,
      set: function (value) {
          this.value = value;
          this.getOnChange();
      }
    }
    window.dinoHighScore.getOnChange = function() {
      if((("dinoHighScore" in window.localStorage) && this.value > window.localStorage?.dinoHighScore) || !("dinoHighScore" in window.localStorage)) {
        window.localStorage.dinoHighScore = this.value
        setUserInput(true)
      }
    }
    window.dinoPlaying.getOnChange = function() {
      setGamePlaying(this.value)
    }
  }, [])


  return (
    <div ref={startDivRef}>
      {
        !gamePlaying &&
      <p className="absolute text-center text-white/50 font-copy text-12px/140 lg:text-8px/140 top-5 left-5">PRESS SPACE/TAP SCREEN</p>
      }
      <style>{DinoStyle}</style>
      <div id="main-frame-error" className="interstitial-wrapper">
        <Resources />
        <div ref={endDivRef}></div>
      </div>
      {
        userInput &&
        <div className='relative flex flex-col justify-center w-3/4 mx-auto sm:flex-row lg:w-1/2 my-spacing-10 gap-spacing-10'>
          <input className="h-10 px-spacing-xs" type="text" id="nickname" name="nickname" placeholder='Enter your name' />
          <input className='h-10 text-black uppercase bg-white border-white px-spacing-master font-primary' type="submit" value="Submit score" id="submit-score" onClick={() => submitHighScore()} />
        </div>
      }
      <HighScore>
            <p className="mb-5 text-center font-primary text-24px/115">Highscore</p>
            <div className="grid gap-5 xs:grid-cols-3 h-[125px]">
              <p className='col-span-1'>
                <span className="">Your best score: </span>
                {window.localStorage.dinoHighScore ?? "Play game at least once to see your best score."}
              </p>
              <div ref={highscoreRef} className="flex flex-col h-full col-span-2 gap-2 overflow-y-auto text-white alltimehs">
                {
                  highscore.length > 0 &&
                  highscore.map((singleScore, index) => {
                    return (
                      <p key={`${singleScore.nick}-${index}`} className={`flex w-full ${index === 0 ? 'text-20px/140 text-yellow' : 'text-16px/115'}`}>
                        <span>
                          {index+1}.
                        </span>
                        <span className='pl-spacing-10'>
                          {singleScore.nick}
                        </span>
                        <span className='ml-auto'>
                          {singleScore.score}
                        </span>
                      </p>
                    )
                  })
                }
              </div>
            </div>
          </HighScore>
    </div>
  );
  
}

export default ChromeDinoComponent;
