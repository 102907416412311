import * as React from "react"
import { motion } from "framer-motion"
import GatsbyTransitionLink from "@components/elements/GatsbyTransitionLink"
import tw from "twin.macro"
import { useStateContext } from "@context/stateContext"
import getTransition from "./../../utils/getTransition"
import playLobster from "@images/lobster_play.webp"
import { useScrollDirection } from "@hooks/useScrollDirection"
import gsap from "gsap"
import LangSwitcher from "@elements/LangSwitcher"
import { Link } from "gatsby"

type NavBarProps = {
  headColor: string
  childrenPage: {
    type: string
    parentId?: string
  }
  currentPage: {
    locale: string
    localizedUri: string
  }
  location?: object
  darkModeActive?: boolean
}

const Wrapper = tw.div`flex flex-col items-start justify-center gap-2 pointer-events-auto`
const BurgerWrap = tw.div`fixed z-[1000] left-spacing-15 md:left-offset-xs cursor-pointer`
const LobsterStart = tw.div`fixed right-spacing-15 md:right-offset-xs cursor-pointer`

const SmallNav = tw.div`absolute left-spacing-15 md:left-offset-xs bottom-spacing-master md:bottom-spacing-master lg:bottom-spacing-xs flex flex-col`
const SmallNavLink = tw(Link)`text-20px/115 lg:text-12px/115 2xl:text-16px/115 3xl:text-20px/115 text-black mt-2`

const headerVariants = {
  home: {
    background: "#fff",
    button: "border-2 border-black opacity-100 text-white text-2xl lg:text-20px/115 3xl:text-2xl 4xl:text-32px/115 py-2 px-3 bg-black transition duration-300 ease-in-out hover:bg-white hover:text-black",
    buttonActive: "!text-black !bg-white !border-black !opacity-100 !visible !relative",
  },
}

const navVariants = {
  initial: {
    x: "-100%",
    backgroundColor: "rgba(255,255,255,0)",
    zIndex: 51,
  },
  animate: {
    x: 0,
    backgroundColor: "rgba(255,255,255,1)",
    zIndex: 999,
    transition: {
      duration: 0.5,
    },
  },
}

const menuItems = [
  { path: "/", name: "Startseite" },
  { path: "/cases/", name: "Cases" },
  { path: "/agentur/", name: "Agentur" },
  { path: "/jobs/", name: "Jobs" },
  { path: "/kontakt/", name: "Kontakt" },
]
const menuItemsEN = [
  { path: "/en/", name: "Home" },
  { path: "/en/cases/", name: "Cases" },
  { path: "/en/davidmartin-agency-of-the-year-2020-2/", name: "Agency" },
  { path: "/en/jobs/", name: "Jobs" },
  { path: "/en/contact/", name: "Contact" },
]

export default function NavBar({ headColor, childrenPage, currentPage, location, darkModeActive }: NavBarProps) {
  const { setMouseInteracting, navOpen, setNavOpen, setTsA, setLobsterGame, lobsterGame, language, darkMode } = useStateContext()
  const scrollDirection = useScrollDirection()
  const burgerRef = React.useRef()
  const lobsterRef = React.useRef()
  const overlayRef = React.useRef()

  const isChildrenPage = !!childrenPage.parentId || childrenPage.type === "jobs"
  const navItemClick = (e, to, transitionState) => {
    e.preventDefault()
    setNavOpen(false)
    setLobsterGame(false)
    setTsA(transitionState)
    getTransition(to, transitionState)
  }
  const navigateBackFromChildren = (e, to, transitionState) => {
    e.preventDefault()
    let __destination = to
    if (location.state?.referrer === null) {
      if (childrenPage.type === "jobs") __destination = `/${childrenPage.type}/`
      else __destination = "/"
    }
    setTsA(transitionState)
    getTransition(__destination, transitionState)
    setLobsterGame(false)
  }
  React.useEffect(() => {
    if (scrollDirection === null) {
      gsap.to(overlayRef.current, { y: -200, duration: 0.5, ease: "power2" })
      gsap.to(burgerRef.current, { y: 0, duration: 0.5, ease: "power2" })
      gsap.to(lobsterRef.current, { y: 0, duration: 0.5, ease: "power2" })
    }
    if (scrollDirection === "down") {
      gsap.to(overlayRef.current, { y: -200, duration: 0.5, ease: "power2" })
      gsap.to(burgerRef.current, { y: -200, duration: 0.5, ease: "power2" })
      gsap.to(lobsterRef.current, { y: -200, duration: 0.5, ease: "power2" })
    }
    if (scrollDirection === "up") {
      gsap.to(overlayRef.current, { y: 0, duration: 0.5, ease: "power2" })
      gsap.to(burgerRef.current, { y: 0, duration: 0.5, ease: "power2" })
      gsap.to(lobsterRef.current, { y: 0, duration: 0.5, ease: "power2" })
    }
  }, [scrollDirection])

  React.useEffect(() => {
    setNavOpen(false)
  }, [location])

  const menuItemsFiltered = language === "de" ? menuItems : menuItemsEN

  return (
    <>
      <div ref={overlayRef} className={`${darkModeActive ? "top-spacing-40" : "top-0"} nav-overlay-bar h-[85px] 2xl:h-[125px] z-50 fixed left-0 w-full`}></div>
      <BurgerWrap
        className={darkModeActive ? "top-spacing-master 2xl:top-spacing-75" : "top-spacing-10 2xl:top-spacing-30"}
        ref={burgerRef}
        onMouseEnter={() => setMouseInteracting(true)}
        onMouseLeave={() => setMouseInteracting(false)}
      >
        {!navOpen && !isChildrenPage && (
          <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => setNavOpen(true)}>
            <path
              d="M7.69995 16.243C20.079 16.243 25.3992 16.243 37.7782 16.243C42.7931 16.243 49.789 17.3273 52.1455 15.7563"
              stroke={headColor === "black" ? "black" : "white"}
              strokeWidth="2.11765"
              strokeLinecap="round"
            />
            <path
              d="M7.71455 26.4384C20.0841 26.924 25.4002 27.1327 37.7697 27.6183C42.7807 27.815 49.7287 29.173 52.145 27.6957"
              stroke={headColor === "black" ? "black" : "white"}
              strokeWidth="2.11765"
              strokeLinecap="round"
            />
            <path
              d="M9.48438 41.7097C11.3453 41.7097 13.2237 41.7837 15.0806 41.6556C20.8241 41.2595 26.407 39.7347 32.1217 39.1684C35.6149 38.8222 39.1495 38.9521 42.6563 38.9521C43.6566 38.9521 44.6569 38.9521 45.6572 38.9521"
              stroke={headColor === "black" ? "black" : "white"}
              strokeWidth="2.11765"
              strokeLinecap="round"
            />
          </svg>
        )}
        {navOpen && !isChildrenPage && (
          <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => setNavOpen(false)}>
            <path d="M14.3306 57.2607C21.5296 47.1436 25.7649 39.2865 33.5296 28.2151C41.2943 17.1436 57.1103 2.88965 57.1103 2.88965" stroke="black" strokeWidth="2.11765" strokeLinecap="round" />
            <path d="M2.11768 12.5869C2.11768 12.5869 8.82356 17.5013 21.8824 25.0013C34.9412 32.5013 37.0589 43.2156 51.7422 51.0276" stroke="black" strokeWidth="2.11765" strokeLinecap="round" />
          </svg>
        )}
        {isChildrenPage && (
          <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={e => navigateBackFromChildren(e, "back", "go-back")}>
            <path
              d="M14.3306 57.2607C21.5296 47.1436 25.7649 39.2865 33.5296 28.2151C41.2943 17.1436 57.1103 2.88965 57.1103 2.88965"
              className={headColor === "black" ? "stroke-black dark:stroke-white" : "stroke-white"}
              strokeWidth="2.11765"
              strokeLinecap="round"
            />
            <path
              d="M2.11768 12.5869C2.11768 12.5869 8.82356 17.5013 21.8824 25.0013C34.9412 32.5013 37.0589 43.2156 51.7422 51.0276"
              className={headColor === "black" ? "stroke-black dark:stroke-white" : "stroke-white"}
              strokeWidth="2.11765"
              strokeLinecap="round"
            />
          </svg>
        )}
      </BurgerWrap>
      <motion.nav
        onMouseEnter={() => setMouseInteracting(true)}
        onMouseLeave={() => setMouseInteracting(false)}
        variants={navVariants}
        initial="initial"
        animate={navOpen ? "animate" : "initial"}
        className="fixed left-0 right-0 z-40 flex flex-col justify-center h-full md:right-auto pl-spacing-15 md:pl-offset-xs md:pr-spacing-75 3xl:pr-spacing-100 4xl:pr-spacing-150"
      >
        <Wrapper>
          {menuItemsFiltered.map(item => {
            return (
              <GatsbyTransitionLink
                key={`navitem-${item.path}`}
                activeClassName={headerVariants.home.buttonActive}
                className={`${headerVariants.home.button}`}
                onClick={e => navItemClick(e, item.path, "from-nav")}
                to={item.path}
              >
                {item.name}
              </GatsbyTransitionLink>
            )
          })}
        </Wrapper>
        <SmallNav>
          <LangSwitcher localizedUri={currentPage?.localizedUri} />
          <SmallNavLink to={language === "de" ? "/impressum/" : "/en/imprint/"}>{language === "de" ? "Impressum" : "Imprint"}</SmallNavLink>
          <SmallNavLink to={language === "de" ? "/datenschutz/" : "/en/privacy-policy/"}>{language === "de" ? "Datenschutzerklärung" : "Privacy Policy"}</SmallNavLink>
        </SmallNav>
      </motion.nav>
      <LobsterStart
        className={darkModeActive ? "top-[47px] sm:top-[45px] 2xl:top-spacing-75 z-[1001]" : "top-[7px] sm:top-[5px] 2xl:top-spacing-30 z-[1001]"}
        ref={lobsterRef}
        onMouseEnter={() => setMouseInteracting(true)}
        onMouseLeave={() => setMouseInteracting(false)}
      >
        {lobsterGame && (
          <svg className="mt-3" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={e => setLobsterGame(false)}>
            <path
              d="M14.3306 57.2607C21.5296 47.1436 25.7649 39.2865 33.5296 28.2151C41.2943 17.1436 57.1103 2.88965 57.1103 2.88965"
              className={headColor === "black" ? "stroke-black dark:stroke-white" : "stroke-white"}
              strokeWidth="2.11765"
              strokeLinecap="round"
            />
            <path
              d="M2.11768 12.5869C2.11768 12.5869 8.82356 17.5013 21.8824 25.0013C34.9412 32.5013 37.0589 43.2156 51.7422 51.0276"
              className={headColor === "black" ? "stroke-black dark:stroke-white" : "stroke-white"}
              strokeWidth="2.11765"
              strokeLinecap="round"
            />
          </svg>
        )}
        <img onClick={() => setLobsterGame(true)} className={lobsterGame ? "hidden" : "h-[80px] scale-75 2xl:scale-100 w-auto"} src={playLobster} alt="" />
      </LobsterStart>
    </>
  )
}
