import React, { createContext, ReactNode, useContext, useReducer } from "react"

export type MousePosition = {
  x: number
  y: number
}

const initialState = {
  mousePosition: { x: 0, y: 0 },
  mouseInteracting: false,
  mouseWhite: false,
  locationFilter: "all",
  positionFilter: "all",
  navOpen: false,
  tsA: "default", //transitionAction
  lobsterGame: false,
  logoBlack: false,
  language: "de",
  darkMode: false,
}

const stateReducer = (state, payload) => {
  switch (payload.action) {
    case "SET_MOUSE_POSITION":
      return { ...state, mousePosition: payload.value }
    case "SET_MOUSE_INTERACTING":
      return { ...state, mouseInteracting: payload.value }
    case "SET_MOUSE_WHITE":
      return { ...state, mouseWhite: payload.value }
    case "SET_LOCATION_FILTER":
      return { ...state, locationFilter: payload.value }
    case "SET_POSITION_FILTER":
      return { ...state, positionFilter: payload.value }
    case "SET_NAVIGATION_OPEN":
      return { ...state, navOpen: payload.value }
    case "SET_TRANSITION_ACTION":
      return { ...state, tsA: payload.value }
    case "SET_LOBSTER_GAME":
      return { ...state, lobsterGame: payload.value }
    case "SET_LOGO_BLACK":
      return { ...state, logoBlack: payload.value }
    case "SET_LANGUAGE":
      return { ...state, language: payload.value }
    case "SET_DARK_MODE":
      return { ...state, darkMode: payload.value }
    default:
      return state
  }
}

export const StateContext = createContext({})

export const StateContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(stateReducer, initialState)

  const StateContextStore = {
    mouseInteracting: state.mouseInteracting,
    setMouseInteracting: (value: boolean) => dispatch({ action: "SET_MOUSE_INTERACTING", value }),
    mouseWhite: state.mouseWhite,
    setMouseWhite: (value: boolean) => dispatch({ action: "SET_MOUSE_WHITE", value }),
    mousePosition: state.mousePosition,
    setMousePosition: (value: MousePosition) => dispatch({ action: "SET_MOUSE_POSITION", value }),
    locationFilter: state.locationFilter,
    setLocationFilter: (value: string) => dispatch({ action: "SET_LOCATION_FILTER", value }),
    positionFilter: state.positionFilter,
    setPositionFilter: (value: string) => dispatch({ action: "SET_POSITION_FILTER", value }),
    navOpen: state.navOpen,
    setNavOpen: (value: string) => dispatch({ action: "SET_NAVIGATION_OPEN", value }),
    tsA: state.tsA,
    setTsA: (value: string) => dispatch({ action: "SET_TRANSITION_ACTION", value }),
    lobsterGame: state.lobsterGame,
    setLobsterGame: (value: string) => dispatch({ action: "SET_LOBSTER_GAME", value }),
    logoBlack: state.logoBlack,
    setLogoBlack: (value: string) => dispatch({ action: "SET_LOGO_BLACK", value }),
    language: state.language,
    languageHandle: (lang: string) => dispatch({ action: "SET_LANGUAGE", value: lang }),
    darkMode: state.darkMode,
    setDarkMode: (value: boolean) => dispatch({ action: "SET_DARK_MODE", value: value }),
    dispatch,
  }

  return <StateContext.Provider value={StateContextStore}>{children}</StateContext.Provider>
}

export const useStateContext = () => useContext(StateContext)
