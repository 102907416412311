import React from "react"
import tw from "twin.macro"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { useStateContext } from "@context/stateContext"
import { globalHistory } from "@reach/router"

const Wrapper = tw.div`cursor-pointer flex items-center md:items-start md:justify-center md:flex-col relative gap-2`
const ActiveButton = tw.button`text-20px/115 lg:text-12px/115 2xl:text-16px/115 3xl:text-20px/115 text-black transition-all duration-300 flex items-center`
const SwitchButton = tw.button`absolute bg-white pr-20 -bottom-2.5 lg:-bottom-2 2xl:-bottom-2.5 text-20px/115 lg:text-12px/115 2xl:text-16px/115 3xl:text-20px/115 text-black transition-all duration-300`

const LangSwitcher = ({ localizedUri = null }) => {
  const [langPop, setLangPop] = React.useState(false)
  const [translationState, setTranslationState] = React.useState(null)
  const { language, languageHandle } = useStateContext()
  const path = globalHistory.location.pathname
  const allCreatedPages = useStaticQuery(graphql`
    {
      allSitePage {
        edges {
          node {
            path
          }
        }
      }
    }
  `)

  const checkIfPageExists = async pathDestination => {
    return allCreatedPages.allSitePage.edges.some(({ node }) => node.path === pathDestination)
  }

  const checkTranslationState = async () => {
    const result = await checkIfPageExists(localizedUri)
    setTranslationState(result)
  }

  const changeLanguage = async (lang, currentPath, setUserPref = false) => {
    setLangPop(false)
    if (language === lang) return
    if (lang === "de") {
      if (setUserPref) window.localStorage.userPrefLanguage = "de"
      if (localizedUri) {
        navigate(localizedUri)
        languageHandle(lang)
        return
      }
      const newPath = currentPath.replace("/en", "")
      navigate(newPath)
      languageHandle(lang)
      return
    }
    if (lang === "en") {
      if (setUserPref) window.localStorage.userPrefLanguage = "en"
      if (localizedUri) {
        try {
          const result = await checkIfPageExists(localizedUri)
          if (!result) return console.log("no page created")
          navigate(localizedUri)
          languageHandle(lang)
        } catch (e) {
          console.error(e)
        }
        return
      }
      const enPath = "/en"
      const newPath = enPath.concat(currentPath)
      const result = await checkIfPageExists(newPath)
      if (!result) return
      navigate(newPath)
      languageHandle(lang)
      return
    }
  }

  const getRedirectLanguage = () => {
    if (typeof navigator === `undefined` && !("userPrefLanguage" in window.localStorage)) {
      return "en"
    }

    const lang = navigator && navigator.language && navigator.language.split("-")[0]
    if ("userPrefLanguage" in window.localStorage) return window.localStorage.userPrefLanguage
    if (!lang) return "en"

    switch (lang) {
      case "de":
        return "de"
      default:
        return "en"
    }
  }

  React.useEffect(() => {
    const urlLang = getRedirectLanguage()
    checkTranslationState()

    if (urlLang === "en" && language === "de") {
      if (path.includes("/en/")) {
        languageHandle("en")
      } else {
        changeLanguage("en", localizedUri)
      }
    }
  }, [])

  React.useEffect(() => {
    if (path.includes("/en/")) {
      languageHandle("en")
    } else {
      languageHandle("de")
    }
    checkTranslationState()
  }, [path]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Wrapper>
      <ActiveButton onClick={() => setLangPop(!langPop)}>
        {language.toUpperCase()}{" "}
        <svg className={`w-3 lg:w-2 2xl:w-2.5 3xl:w-3 ml-2 ${langPop && "rotate-180"}`} width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.41 0.589844L6 5.16984L10.59 0.589844L12 1.99984L6 7.99984L0 1.99984L1.41 0.589844Z" fill="black" />
        </svg>
      </ActiveButton>
      {langPop && (
        <SwitchButton className="transform translate-y-full peer" onClick={() => translationState && changeLanguage(language === "de" ? "en" : "de", path, true)}>
          {language === "de" ? "EN" : "DE"}
        </SwitchButton>
      )}
      {!translationState && (
        <p className="error-message md:order-[-5] md:pb-2 text-red-500 transition-all duration-300 text-12px/115 md:text-20px/115 lg:text-12px/115 2xl:text-16px/115 3xl:text-20px/115 md:opacity-0 md:peer-hover:opacity-100">
          {language === "de" ? "Diese Seite gibt es (noch) nicht auf Englisch, sorry!" : "This page is not available in German (yet), sorry!"}
        </p>
      )}
    </Wrapper>
  )
}

export default LangSwitcher
