import { navigate } from "gatsby";
import gsap from "gsap";
import SplitText from "gsap/SplitText";

const getTransition = (to, ts) => {
  gsap.registerPlugin(SplitText)
  let q = gsap.utils.selector(document.body)
  gsap.set(document.documentElement, { overflowY: "hidden" })
  
  //to home from NAV nad LOGO (clip to bottom)
  if((ts === 'from-nav' || ts === 'logo-home') && (to === '/' || to === '/en/')){
    let toHomeTL = gsap.timeline({paused: true})
    toHomeTL.fromTo(q('#transition-overlay'), {zIndex: 999, top: 0, bottom: 0, clipPath: "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)"}, {clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)", duration: .35, onComplete: () => {
      navigate(to)
      window.scrollTo(0,0)
      toHomeTL.to(q('#transition-overlay'),  {delay: .3, clipPath: "polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)", duration: .35, ease: 'circ.easeOut', onComplete: () => {
        gsap.set(document.documentElement, { overflowY: "auto" })
      }})
    }})
    return (
      toHomeTL.play()
    )
  }
  //to CASES from NAV (clip to right)
  if(ts === 'from-nav'  &&  to.includes('cases')){
    let toCasesTL = gsap.timeline({paused: true})
    toCasesTL.fromTo(q('#transition-overlay'), {zIndex: 999, top: 0, bottom: 0, clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)"}, {clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)", duration: .5, onComplete: () => {
      navigate(to)
      window.scrollTo(0,0)
      toCasesTL.to(q('#transition-overlay'),  {delay: .3, clipPath: "polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)", duration: .35, ease: 'circ.easeOut', onComplete: () => {
        gsap.set(document.documentElement, { overflowY: "auto" })
      }})
    }})
    return (
      toCasesTL.play()
    )
  }
  //get back to home (clip to left)
  if(ts === 'home-back'){
    let toHomeTL = gsap.timeline({paused: true})
    toHomeTL.fromTo(q('#transition-overlay'), {zIndex: 999, top: 0, bottom: 0, clipPath: "polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)"}, {clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)", duration: .5, onComplete: () => {
      navigate(to)
      window.scrollTo(0,0)
      toHomeTL.to(q('#transition-overlay'),  {delay: .3, clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)", duration: .35, ease: 'circ.easeOut', onComplete: () => {
        gsap.set(document.documentElement, { overflowY: "auto" })
      }})
    }})
    return (
      toHomeTL.play()
    )
  }
  //footer links (clip to top)
  if(ts === 'clip-bot-top'){
    let footerLinksTL = gsap.timeline({paused: true})
    footerLinksTL.fromTo(q('#transition-overlay'), {zIndex: 999, top: 0, bottom: 0, clipPath: "polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)"}, {clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)", duration: .5, onComplete: () => {
      navigate(to)
      window.scrollTo(0,0)
      footerLinksTL.to(q('#transition-overlay'),  {delay: .3, clipPath: "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)", duration: .35, ease: 'circ.easeOut', onComplete: () => {
        gsap.set(document.documentElement, { overflowY: "auto" })
      }})
    }})
    return (
      footerLinksTL.play()
    )
  }
  //from newsticker (circle clip)
  if(ts === 'clip-circle'){
    let fromNewsTickerTL = gsap.timeline({paused: true})
    fromNewsTickerTL.fromTo(q('#transition-overlay'), {zIndex: 999, top: 0, bottom: 0, clipPath: "circle(0% at 50% 50%)"}, {clipPath: "circle(100% at 50% 50%)", duration: .5, onComplete: () => {
      navigate(to)
      fromNewsTickerTL.to(q('#transition-overlay'),  {delay: .3, clipPath: "circle(0% at 50% 50%)", duration: .35, ease: 'circ.easeOut', onComplete: () => {
        gsap.set(document.documentElement, { overflowY: "auto" })
      }})
    }})
    return (
      fromNewsTickerTL.play()
    )
  }
  //to JOBS / KONTAKT from NAV (clip to bottom & bigText heading splitAni)
  if(ts === 'from-nav' && ['jobs', 'kontakt', 'contact', 'aquarium'].some(substring => ['jobs', 'kontakt', 'contact', 'aquarium'].includes(substring))){
    let navToJobsTL = gsap.timeline({paused: true})
    navToJobsTL.fromTo(q('#transition-overlay'), {zIndex: 999, top: 0, bottom: 0, clipPath: "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)"}, {clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)", duration: .5, onComplete: () => {
      navigate(to)
      navToJobsTL.to(q('#transition-overlay'), {duration: .2, zIndex: 999, onComplete: () => {
        q = gsap.utils.selector(document.body)
        const headline = new SplitText(q(".big-text-headline"), { type: "words,chars" })
        navToJobsTL.to(q('#transition-overlay'),  {delay: .3, clipPath: "polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)", duration: .35, ease: 'circ.easeOut', onComplete: () => {
          gsap.set(document.documentElement, { overflowY: "auto" })
        }})
        gsap.from(headline.chars, {
          autoAlpha: 0,
          yPercent: 100,
          duration: 0.7,
          ease: "power2",
          stagger: {
            each: 0.05,
          },
        })
      }})

    }})
    return (
      navToJobsTL.play()
    )
  }
  //to AGENTUR from NAV (clip to bottom & bigText heading splitAni + bigText imageAni)
  if(ts === 'from-nav' && (to === '/agentur/' || to === '/agency/')){
    let navToJobsTL = gsap.timeline({paused: true})
    navToJobsTL.fromTo(q('#transition-overlay'), {zIndex: 999, top: 0, bottom: 0, clipPath: "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)"}, {clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)", duration: .5, onComplete: () => {
      navigate(to)
      navToJobsTL.to(q('#transition-overlay'), {duration: .2, zIndex: 999, onComplete: () => {
        q = gsap.utils.selector(document.body)
        const headline = new SplitText(q(".big-text-headline"), { type: "words,chars" })
        navToJobsTL.to(q('#transition-overlay'),  {delay: .3, clipPath: "polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)", duration: .35, ease: 'circ.easeOut', onComplete: () => {
          gsap.set(document.documentElement, { overflowY: "auto" })
        }})
        gsap.from(q('.big-text-image'), {
          delay: 0.5, top:0, bottom: 0, width: '100%', ease: "power2", duration: .5, clearProps: 'width, bottom'
        })
        gsap.from(headline.chars, {
          delay: 0.4,
          autoAlpha: 0,
          yPercent: -100,
          duration: 0.7,
          ease: "power2",
          stagger: {
            each: 0.05,
          },
        })
      }})

    }})
    return (
      navToJobsTL.play()
    )
  }

  //to SINGLE JOB from JobCard (clip to right yellow)
  if(ts === 'from-jobcard' && to.includes('/jobs/')){
    let toHomeTL = gsap.timeline({paused: true})
    toHomeTL.fromTo(q('#transition-overlay'), {backgroundColor: '#f1fc00',top: 0, bottom: 0, clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)"}, {clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)", duration: .5, onComplete: () => {
      navigate(to)
      // window.scrollTo(0,0)
      toHomeTL.to(q('#transition-overlay'),  {delay:.3,clipPath: "polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)", duration: .5, ease: 'circ.easeOut', clearProps: 'backgroundColor', onComplete: () => {
        gsap.set(document.documentElement, { overflowY: "auto" })
      }})
    }})
    return (
      toHomeTL.play()
    )
  }

  //GO BACK
  if(ts === 'go-back'){
    let goBackTL = gsap.timeline({paused: true})
    goBackTL.fromTo(q('#transition-overlay'), {zIndex: 999, top: 0, bottom: 0, clipPath: "polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)"}, {clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)", duration: .35, onComplete: () => {
      if(to === 'back') history.back()
      else navigate(to)
      goBackTL.to(q('#transition-overlay'),  {delay: .5, clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)", duration: .65, ease: 'circ.easeOut', onComplete: () => {
        gsap.set(document.documentElement, { overflowY: "auto" })
      }})
    }})
    // goBackTL.fromTo(q('#transition-overlay'), {autoAlpha: 1, zIndex: 999, top: 0, bottom: 0, clipPath: "polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)"}, {clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)", duration: .35, onComplete: () => {
    //   if(to === 'back') history.back()
    //   else navigate(to)
    //   goBackTL.to(q('#transition-overlay'),  {delay: .3, clipPath: "polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)",  duration: 1, ease: 'circ.easeOut', onComplete: () => {
    //     gsap.set(document.documentElement, { overflowY: "auto" })
    //   }})
    // }})
    return (
      goBackTL.play()
    )
  }

}
export default getTransition