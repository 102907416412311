import "./src/styles/global.css"
import React from "react"
import { StateContextProvider } from "@context/stateContext"
import Layout from "@root/layout"

export const wrapPageElement = ({ props, element }) => {
  return (
    <StateContextProvider>
      <Layout {...props}>{element}</Layout>
    </StateContextProvider>
  )
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location && location.state) location.state.referrer = prevLocation ? prevLocation.pathname : null
}

export const shouldUpdateScroll = ({ prevRouterProps, routerProps: { location }, getSavedScrollPosition }) => {
  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), 200)
  } else {
    const savedPosition = getSavedScrollPosition(location)
    let __savedPosition = [0, 0]
    let timeout = 300
    let regexp = /cases\/[a-z]/i
    //save scroll for going back from cases
    if (savedPosition[1] > 0 && prevRouterProps?.location?.pathname?.match(regexp)) {
      __savedPosition = [savedPosition[0], savedPosition[1]]
      timeout = 800
    }
    //save scroll for going back from any other (jobs, kontakt)
    if (savedPosition[1] > 0 && !prevRouterProps?.location?.pathname?.match(regexp)) {
      __savedPosition = [savedPosition[0], savedPosition[1]]
    }
    window.setTimeout(() => window.scrollTo(...__savedPosition), timeout)
  }
  return false
}
