import React from "react"
import tw from "twin.macro"
import { useStateContext } from "@context/stateContext"
import ChromeDinoGame from "@modules/custom/dino-lobster"

const Section = tw.section`fixed h-screen bg-black/80 inset-0 z-[1000] overflow-hidden flex items-center justify-center`
const Wrap = tw.div`w-11/12 sm:w-3/4 max-w-[650px] bg-black relative`

type LobDinoGameProps = {
  children?: React.ReactNode
}

const LobDinoGame = ({}: LobDinoGameProps) => {
  const { lobsterGame, setLobsterGame } = useStateContext()

  React.useEffect(() => {
    if (lobsterGame) document.documentElement.style.overflow = "hidden"
    return () => (document.documentElement.style.overflow = "auto")
  }, [lobsterGame])

  return (
    lobsterGame && (
      <Section onClick={() => setLobsterGame(false)}>
        <Wrap
          className="transform lg:scale-150"
          style={{
            aspectRatio: "16 / 6",
          }}
          onClick={e => e.stopPropagation()}
        >
          <ChromeDinoGame />
        </Wrap>
      </Section>
    )
  )
}

export default LobDinoGame
